import axiosInstance from '../axiosInstance';
import { createCache } from '../../utils/cacheUtils';

const defaultSearchParams = {
  page: 1,
  limit: 20,
};
const resultCache = createCache(100, 20000);
const observationsCache = createCache(100, 20000);

function buildResultsQuery(params) {
  const {
    page = 0,
    limit = 20,
    client,
    labId,
    searchTerm,
    testStatus,
    patientId,
    fromDate,
    toDate,
    clients,
  } = params;

  const query = { page, limit };

  if (client) query.client = client;

  if (clients) {
    const validClients = labId ? clients?.every((client) => (client !== undefined && client !== labId)) : clients?.every((client) => client !== undefined);
    if (validClients) query.clients = clients;
  }

  if (patientId) query.patientId = patientId;

  if (testStatus) query.testStatus = testStatus;

  if (labId) query.labId = labId;

  if (searchTerm && searchTerm.trim().length) query.search = searchTerm;

  if (fromDate) query.from = fromDate;
  if (toDate) query.to = toDate;
  return query;
}

async function getPatientResults(searchParams = defaultSearchParams) {
  const params = { ...defaultSearchParams, ...searchParams };
  if (!params.patientId) throw new Error('Invalid params');
  const query = buildResultsQuery(params);

  try {
    const results = await axiosInstance.get('/result/patient', {
      // headers: { 'x-client-id': params.xClientId },
      params: query,
    });
    return results.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
async function getResults(searchParams = defaultSearchParams) {
  // create const for { ...defaultSearchParams, ...searchParams };
  const params = { ...defaultSearchParams, ...searchParams };
  const query = buildResultsQuery(params);

  try {
    const results = await axiosInstance.get('/result', {
      // headers: { 'x-client-id': params.xClientId },
      params: query,
    });
    return results.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const createResult = async (values) => {
  try {
    const response = await axiosInstance.post(`/result`, {
      ...values,
    });
    const result = response.data;
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const createResultDirectly = async (values) => {
  try {
    const response = await axiosInstance.post(`/result/create-directly`, {
      ...values,
    });
    const result = response.data;
    return result;
  } catch (err) {
    console.error(err);
    throw Error(err);
  }
};

async function markResultAsRead(id = [], isRead) {
  try {
    const data = JSON.stringify({
      ids: id,
      isRead,
    });

    const config = {
      method: 'patch',
      url: '/result/mark-as-read',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const response = await axiosInstance(config);
    const result = response.data;
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
}

async function getResultsBySsn(searchParams = defaultSearchParams) {
  const {
    page = 0,
    limit = 20,
    ssn,
  } = { ...defaultSearchParams, ...searchParams };

  const query = { page, limit };
  if (!ssn) throw new Error('Invalid params');

  query.ssn = ssn;
  // TODO : remove this when have a better solution
  query.clients = ['00000000000000000000'];

  try {
    const results = await axiosInstance.get('/result/by-ssn', {
      params: query,
    });
    return results.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getResultsByIdentityUserId(searchParams = defaultSearchParams) {
  const { page = 0, limit = 20 } = { ...defaultSearchParams, ...searchParams };
  const query = { page, limit };
  try {
    const results = await axiosInstance.get('/result/by-identity-user/by-user-and-clients', {
      params: query,
    });
    return results.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const getResultMemoized = async (id = '') => {
  const cacheKey = `result-${id}`;

  const cachedData = resultCache.get(cacheKey);
  if (cachedData) {
    return cachedData;
  }

  try {
    const response = await axiosInstance.get(`/result/${id}`);
    if (response.data) {
      resultCache.set(cacheKey, response.data);
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching result:', error);
    return null;
  }
};

async function getResult(id = '') {
  return getResultMemoized(id);
}

async function getResultsByIds(ids = []) {
  try {
    const response = await axiosInstance.post(`/result/by-ids`, { ids });
    const result = response.data;
    return result;
  } catch (err) {
    console.error(err);
    throw Error(err);
  }
}

async function getBiomarkersByResultIds(ids = []) {
  return axiosInstance.post(`/result/bios-by-ids`, { ids });
}

async function getObservationsByResultIds(ids = []) {
  return axiosInstance.post(`/result/obs-by-result-ids`, { ids });
}


const getBulkSignedFile = async (paths) => {
  try {
    const response = await axiosInstance.post('/result/bulk-signed-files', {
      files: paths,
    });
    const result = response.data;
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getObservationByIdsMemoized = async (ids = []) => {
  const cacheKey = `observations-${ids.sort().join('-')}`;

  const cachedData = observationsCache.get(cacheKey);
  if (cachedData) {
    return cachedData;
  }

  try {
    const response = await axiosInstance.get('/result/observations', {
      params: {
        ids,
      },
    });

    if (response.data) {
      observationsCache.set(cacheKey, response.data);
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching observations:', error);
    return null;
  }
};

const getObservationByIds = async (ids = []) => {
  return getObservationByIdsMemoized(ids);
};

const updateResult = async (id, udpatedValues) => {
  try {
    const response = await axiosInstance.patch(`/result/${id}`, {
      ...udpatedValues,
    });
    const observations = response.data;
    return observations;
  } catch (err) {
    console.error(err);
    throw Error(err);
  }
};

const sendResultsFile = async (data) => {
  try {
    return await axiosInstance.post('/result/file', data);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const checkResultExists = async (fhirId) => {
  try {
    const response = await axiosInstance.get('/result/ping', {
      params: {
        fhirId,
      },
    });
    return response.data?.doesExist;
  } catch (err) {
    console.error(err);
    return null;
  }
};


const getAnomalyProcessState = async (id) => {
  try {
    const response = await axiosInstance.get('/result/ping-process-state', {
      params: {
        id,
      },
    });
    return response?.data.anomalyProcessState;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getSharedResults = async (filters) => {
  return axiosInstance.get('/share/result/by-user-and-clients', { params: filters });
};

const getSharedResult = async (resultId)  => {
  return axiosInstance.get(`/share/result/${resultId}`);
}

const deleteResult = async (id) => {
  try {
    const response = await axiosInstance.delete(
      `/result/${id}`
    );
    const result = response.data;
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const deleteManyResults = async (ids) => {
  try {
    const response = await axiosInstance.delete(
      `/result/delete/many/by-ids`,
      {
        params: {
          resultIds: ids,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getHL7ObservationsByOrderId = async (orderId) => {
  try {
    const response = await axiosInstance.get('/result/hl7-observations', {
      params: {
        orderId,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

const resultApi = {
  getResults,
  createResult,
  deleteResult,
  deleteManyResults,
  createResultDirectly,
  markResultAsRead,
  getResult,
  getPatientResults,
  getResultsByIds,
  getBiomarkersByResultIds,
  getBulkSignedFile,
  getObservationByIds,
  updateResult,
  sendResultsFile,
  checkResultExists,
  getResultsBySsn,
  getResultsByIdentityUserId,
  getSharedResults,
  getSharedResult,
  getObservationsByResultIds,
  getAnomalyProcessState,
  getHL7ObservationsByOrderId,
};

export default resultApi;
