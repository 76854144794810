import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import { useCustomForm } from 'hooks';
import moment from 'moment';

import { ErrorToast, Input, Select, Text, Cleave, Button } from 'components';
import { schema } from '../patientSignupV1/schema';
import logo from '../../../assets/new/patientApp/logo.svg';
import history from '../../../history';

import './patientSignupV2.scss';

const PatientSignupV2 = ({
  details,
  updateDetails,
  createUser,
  createUserLoading,
  location,
}) => {
  const {
    Controller,
    handleSubmit,
    formState: { errors },
    control,
  } = useCustomForm(schema, {
    gender: details?.gender || '',
    firstName: details?.firstName || '',
    lastName: details?.lastName || '',
    dateOfBirth: details?.dateOfBirth
      ? moment(details?.dateOfBirth).format('DD-MM-YYYY')
      : '',
    email: details?.email || '',
    ssn: details?.ssn || '',
  });

  const [disableEmail, setDisableEmail] = useState(false);

  useEffect(() => {
    console.log(errors, 'error');
    if (Object.keys(errors).length > 0)
      toast.error(<ErrorToast message="Please fill out all required fields" />);
  }, [errors]);

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    console.log('params', params.get('email'), params.get('type'));

    if (params.get('email')) {
      updateDetails({ ...details, email: params.get('email') });
    }
    if (params.get('external')) {
      setDisableEmail(true);
    }
  }, []);

  const onRegister = async () => {
    await createUser();
  };

  const onValChange = (field, value, callback) => {
    updateDetails({ ...details, [field]: value });
    callback(value);
  };

  return (
    <div className="signup">
      <div className="signup__logo">
        <img className="login__card__logo" alt="logo" src={logo} />
      </div>
      <div className="signup__left">
        <div className="signup__wraps signup__wraps__br-left">
          <h1>Better Health Starts Here</h1>
        </div>
      </div>
      <div className="signup__right">
        <div className="signup__wraps signup__wraps__br-right">
          <h2>Sign up</h2>
          <p>Start your 30-day free trial.</p>
          <form onSubmit={handleSubmit(onRegister)} className="signup__form">
            <div className="signup__form-group">
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="u-patient-form-v1__form__input"
                    label="First Name"
                    errorMessage=""
                    placeholder="Jane"
                    size="fullWidth"
                    value={value}
                    invalid={errors?.firstName}
                    onChange={(e) =>
                      onValChange('firstName', e.target.value, onChange)
                    }
                  />
                )}
              />
            </div>
            <div className="signup__form-group">
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="u-patient-form-v1__form__input"
                    label="Last Name"
                    errorMessage=""
                    placeholder="Doe"
                    size="fullWidth"
                    value={value}
                    invalid={errors?.lastName}
                    onChange={(e) =>
                      onValChange('lastName', e.target.value, onChange)
                    }
                  />
                )}
              />
            </div>
            <div className="signup__form-group">
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    border
                    label="Gender"
                    placeholder="Female"
                    value={value}
                    onChange={(e, { value }) =>
                      onValChange('gender', value, onChange)
                    }
                    options={[
                      { text: 'Female', value: 'female' },
                      { text: 'Male', value: 'male' },
                      { text: 'Other', value: 'other' },
                      { text: 'Unknown', value: 'unknown' },
                    ]}
                    search
                    size="fullWidth"
                    errorMessage=""
                    selection
                    className="u-patient-form__form__input"
                    invalid={errors?.gender}
                  />
                )}
              />
            </div>
            <div className="signup__form-group">
              <Controller
                name="dateOfBirth"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Cleave
                    label="Birth Date"
                    placeholder="DD-MM-YYYY"
                    options={{
                      date: true,
                      datePattern: ['d', 'm', 'Y'],
                      delimiter: '-',
                    }}
                    onChange={(e) =>
                      onValChange('dateOfBirth', e.target.value, onChange)
                    }
                    errorMessage=""
                    value={value}
                    size="fullWidth"
                    invalid={errors?.dateOfBirth}
                  />
                )}
              />
            </div>
            <div className="signup__form-group">
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    border
                    className="u-patient-form-v1__form__input"
                    label="Email Address"
                    placeholder="john-doe@riverr.ai"
                    size="fullWidth"
                    disabled={disableEmail}
                    onChange={(e) =>
                      onValChange('email', e.target.value, onChange)
                    }
                    errorMessage="This field requires 7 - 22 characters"
                    value={value}
                    invalid={errors?.email}
                  />
                )}
              />
            </div>
            <Button
              type="submit"
              size="fullWidth"
              className="signup__submit mt-2"
              isLoading={createUserLoading}
            >
              Get started
            </Button>
            {/* <Button className="signup__google-signup">Sign up with Google</Button> */}
          </form>
          <p className="signup__login mt-2">
            Already have an account?{' '}
            <a onClick={() => history.push('/')} href="#">
              Log in
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default PatientSignupV2;
