import axiosInstance from '../axiosInstance';
import { createCache } from '../../utils/cacheUtils';

const patientCache = createCache(100, 20000);
const biomarkerCache = createCache(100, 20000);

const patientApi = {
  async deletePatient(patientId) {
    const patientsResponse = await axiosInstance.delete(
      `/patient/${patientId}`
    );
    return patientsResponse?.data;
  },

  async createPatient(payload) {
    const patientsResponse = await axiosInstance.post('/patient', payload);
    return patientsResponse?.data;
  },

  async bulkUpload(payload) {
    const patientsResponse = await axiosInstance.post('/patient/bulkUpload', payload);
    return patientsResponse?.data;
  },

  async updatePatient(payload) {
    const patientsResponse = await axiosInstance.put('/patient', payload);
    return patientsResponse?.data;
  },

  async getPatients(filters) {
    const params = { ...filters };
    if(!params.isDeleted) delete params.isDeleted;
    const patientsResponse = await axiosInstance.get('/patient', {
      params,
    });
    return patientsResponse?.data;
  },

  async getSharedPatients(filters) {
    const params = { ...filters };
    if(!params.isDeleted) delete params.isDeleted;
    const patientsResponse = await axiosInstance.get('/share/patient', {
      params,
    });
    return patientsResponse?.data;
  },

  async getPatient(patientId) {
    const cacheKey = `patient-${patientId.replace('Patient/', '')}`;

    const cachedData = patientCache.get(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    try {
      const patientsResponse = await axiosInstance.get(
        `/patient/${patientId.replace('Patient/', '')}`
      );

      if (patientsResponse?.data) {
        patientCache.set(cacheKey, patientsResponse.data);
      }

      return patientsResponse?.data;
    } catch (error) {
      console.error('Error fetching patient:', error);
      return null;
    }
  },

  async getSharedPatient(patientId) {
    const patientsResponse = await axiosInstance.get(
      `/share/patient/${patientId.replace('Patient/', '')}`
    );
    return patientsResponse?.data;
  },

  async createPatientByIdentityUserIfNotExist() {
    const patientsResponse = await axiosInstance.post(
      `/patient/by-identity-user`
    );
    return patientsResponse?.data;
  },

  async createFhirPatientIfNotExist(patientId) {
    const patientsResponse = await axiosInstance.post(
      '/patient/by-portal-patient',
      { patientId }
    );
    return patientsResponse?.data;
  },

  async getPatientBiomarkerOverview(patientId) {
    const cacheKey = `biomarker-overview-${patientId}`;

    const cachedData = biomarkerCache.get(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    try {
      const patientsResponse = await axiosInstance.get(
        '/patient/biomarker-overview',
        {
          params: { patientId },
        }
      );

      if (patientsResponse?.data) {
        biomarkerCache.set(cacheKey, patientsResponse.data);
      }

      return patientsResponse?.data;
    } catch (error) {
      console.error('Error fetching biomarker overview:', error);
      return null;
    }
  },

  async getPatientBiomakerValues(patientId) {
    const patientsResponse = await axiosInstance.get(
      '/patient/model-biomarker',
      {
        params: { patientId },
      }
    );
    return patientsResponse?.data;
  },
};

export default patientApi;
