import axios from 'axios';

import { PDF_EXTRACTOR_END_POINT } from '../constants';
import { createCache } from '../utils/cacheUtils';
const riskModelsCache = createCache(100, 20000);

const defaultSearchParams = {
  page: 1,
  limit: 20,
};

const axiosInstance = axios.create({
  baseURL: PDF_EXTRACTOR_END_POINT,
  // baseURL: 'http://localhost:8080',
});

const requestHandler = (request) => {
  if (
    localStorage.getItem('AUTH_TOKEN') &&
    JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
  ) {
    request.headers['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token}`
  }

  // if (
  //   localStorage.getItem('AUTH_TOKEN') &&
  //   JSON.parse(localStorage.getItem('AUTH_TOKEN'))?.token
  // ) {
  //   request.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiIiwiU3RhbmRhcmRDbGFpbXMiOnsiYXVkIjoiTXlJRCIsImV4cCI6IjIwMjMtMTEtMTlUMDU6MzI6NDMuNjM0WiIsImp0aSI6IjNkMzU0MzIxLTc3ZjUtNGZjNy1iZTE5LWUwNmQzMzUzMmU2ZiIsImlhdCI6MTcwMDExMjc2MywiaXNzIjoiIiwibmJmIjoxNzAwMTEyNzYzLCJzdWIiOiJjNTM4MjFlMy1jYzEzLTQ3MTktYjI4ZS02YTcxOGE0MzgxZDkifSwiaWF0IjoxNzAwMTEyNzYzLCJleHAiOjE3MDAxOTkxNjN9.TtCGPwuvsC2OIda83tmgCBb0kVdHwClh6pyAj6bXD0w`;
  // }

  // const clientId = localStorage.getItem('CLIENT_ID');
  // if (clientId) request.headers['x-client-id'] = clientId;

  return request;
};


const errorHandler = (error) => {
  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);


const getAllRiskModelsDataWithCache = async (riskModelParams) => {
  const { id, shouldFetchOnlyPreviousStatus = false } = riskModelParams;
  const cacheKey = `risk-models-${id}-${shouldFetchOnlyPreviousStatus}`;

  const cachedData = riskModelsCache.get(cacheKey);
  if (cachedData) {
    return cachedData;
  }

  try {
    const response = await axiosInstance.post('/api/v1/medicalProfile/riskAssessment/all/getByUser',
      {
        shouldFetchOnlyPreviousStatus,
      },
      {
        params: {
          id,
        },
      }
    );

    if (response.data) {
      riskModelsCache.set(cacheKey, response.data);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching risk models data:', error);
    return null;
  }
};
async function getAllRiskModelsData(riskModelParams) {
  return getAllRiskModelsDataWithCache(riskModelParams);
}

async function getPatients(filters) {
    return axiosInstance.get('/v1/patient', { params: { ...filters } });
}

async function getPatientByEmail(email) {
  return axiosInstance.post('/v1/patient/find-by-email', {email});
}

async function getCurrentPatient(email) {
  return axiosInstance.post('/v1/patient/current');
}

async function getResults(searchParams = defaultSearchParams) {
  const {
    page = 0,
    limit = 20,
    client,
    labId,
    searchTerm,
    testStatus,
    patientId,
    fromDate,
    toDate,
    clients,
    xClientId,
  } = { ...defaultSearchParams, ...searchParams };

  const query = { page, limit };

  if (client) query.client = client;

  if (clients) {
    const validClients = labId ? clients?.every((client) => (client !== undefined && client !== labId)) : clients?.every((client) => client !== undefined);
    if (validClients) query.clients = clients;
  }

  if (patientId) query.patientId = patientId;

  if (testStatus) query.testStatus = testStatus;

  if (labId) query.labId = labId;

  if (searchTerm && searchTerm.trim().length) query.search = searchTerm;

  if (fromDate) query.from = fromDate;
  if (toDate) query.to = toDate;

  try {
    const results = await axiosInstance.get('/v1/result/patient/current', {
      params: query,
    });
    return results.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getMedicalProfile(userId) {
    try {
        const profileResponse = await axiosInstance.post(
        '/api/v1/medicalProfile/provider/getByUser',
        { userId: userId },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        return profileResponse.data?.data?.profile;
    } catch (error) {
        console.error(error);
        return null;
    }
}

const patientDashboardPublicApi = {
    getAllRiskModelsData,
    getPatients,
    getResults,
    getMedicalProfile,
    getPatientByEmail,
    getCurrentPatient,
  };

  export default patientDashboardPublicApi;

