import axios from '../identityAxiosInstance';
import { createCache } from '../../utils/cacheUtils';

const clientCache = createCache(100, 20000);

const userApi = {
  async getCurrentClient() {
    return axios.post('/myid/client');
  },
  async getAllClients({ offset = 0, limit = 100, search }) {
    return axios.post(
      '/myclient/backoffice/client/GetAll',
      { search },
      {
        params: {
          limit,
          offset,
        },
      }
    );
  },
  async getClientById(clientId) {
    const cacheKey = `client-${clientId}`;

    const cachedData = clientCache.get(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    try {
      const response = await axios.post(
        `/myclient/backoffice/client/GetByClientId?id=${clientId}`
      );

      if (response?.data) {
        clientCache.set(cacheKey, response);
      }

      return response;
    } catch (error) {
      console.error('Error fetching client:', error);
      throw error;
    }
  },
  async getPublicClientById(clientId) {
    return axios.post(`/myclient/client/GetByClientId?id=${clientId}`);
  },
  async getClientUsers(clientId) {
    return axios.post(
      `/myclient/backoffice/user/GetByClient?client-id=${clientId}`
    );
  },
  async createClient(client) {
    return axios.post('/myclient/backoffice/client', client);
  },
  async updateClientById(clientId, client) {
    delete client.integrations;
    delete client._id;
    delete client.__v;
    delete client.createdAt;
    delete client.updatedAt;
    return axios.post(`/myclient/backoffice/client/UpdateClient`, client);
  },
  async createClientGroup(name) {
    return axios.post('/myclient/backoffice/client-group', {
      userIds: [],
      clientIds: [],
      name,
    });
  },
  async deleteClientGroup(groudId) {
    return axios.post(
      `/myclient/backoffice/client-group/delete/${groudId}`,
      null
    );
  },
  async getAllClientGroups() {
    return axios.post('/myclient/backoffice/client-group/GetAll', null, {
      params: {
        offset: 0,
        limit: 100,
      },
    });
  },
  async updateClientGroup(groupId, clientGroup) {
    delete clientGroup.users;
    delete clientGroup.clients;
    delete clientGroup.createdAt;
    delete clientGroup.updatedAt;
    delete clientGroup.userId;
    delete clientGroup.__v;
    delete clientGroup._id;
    return axios.post(
      `/myclient/backoffice/client-group/update/${groupId}`,
      clientGroup
    );
  },
  async addUserToGroup(userId, clientGroupId) {
    return axios.post('/myclient/backoffice/client-group/add-user', {
      userId,
      clientGroupId,
    });
  },
  async getGroupById(id) {
    return axios.post('/myclient/backoffice/client-group/GetById', null, {
      params: {
        id,
      },
    });
  },
  async getClientsByToken() {
    return axios.post('/myclient/client/current', null);
  },
};

export default userApi;
